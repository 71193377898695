import React from 'react'

const Headerimage = () => {
  return (
    <div className='header-image-container'>
        <div className="header-image headerimage" ></div>
    </div>
  )
}

export default Headerimage
