import axiosinstance from '../../../../axios/axiosInstance';
import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { PlayerProfileContext } from '../../../../context/PlayerProfileContext';
import { Link } from 'react-router-dom';
import { useLocation ,useNavigate} from 'react-router-dom';

const About = ({userData}) => {
    const [profile, setProfile] = useState({});
    const [playerProfile, setPlayerProfile] = useState({});
    const { playerProfileData, setPlayerProfileData } = useContext(PlayerProfileContext);
    const location = useLocation();
    const playerId = location.state?.playerId || '';
    console.log(playerId);
    useEffect(() => {
        setProfile(prevProfile => ({
            ...prevProfile,
            ...playerProfileData
        }));
    }, [playerProfileData]);

    const handlechange = (e) => {
        setPlayerProfile({ ...playerProfile, [e.target.name]: e.target.value });
    }

    const submitprofile = () => {
        const updated_value = {
            ...profile,
            ...playerProfile
        };
        console.log(playerProfile);
        const formData = new FormData();
        for (const key in updated_value) {
            if (key !== 'profilePicture') {
                formData.append(key, updated_value[key]);
            }
        }

        if (updated_value.profilePicture) {
            formData.append('profilePicture', updated_value.profilePicture);
        }

        axiosinstance.post("/user/profile_update", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: Cookies.get('uid'),
                userid: Cookies.get('user_id')
            }
        }).then((response) => {
            console.log(response);
            if (response.status == 200) {
                setProfile({
                    ...profile,
                    ...playerProfile
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    console.log("This is userDtaa",userData);

    return (
        <>
            {
                userData ?
                    <div className='container-fluid'>
                        <div className='row mt-4'>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Name</h6>
                                    <b>{userData.name || 'NULL'}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Gender</h6>
                                    <b>{userData.gender || 'NULL'}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain DOB</h6>
                                    <b>{userData.dob || 'NULL'}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Email</h6>
                                    <b>{userData.email || 'NULL'}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <div className='row'>
                                    <h6>Captain Address</h6>
                                    <b>{userData.city + ", " + userData.state}</b>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row my-3'>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Playing Role</label>
                                <h6>{userData.playing_role}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Playing Position</label>
                                <h6>{userData.preferred_playing_position}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Batting Style</label>
                                <h6>{userData.batting_style || 'NULL'}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Bowling Style</label>
                                <h6>{userData.bowling_style || 'NULL'}</h6>
                            </div>
                            <h5 className='mt-3'>Physical Attributes</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Player's Height</label>
                                <h6>{userData.height || 'NULL'} cm</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Player's Weight</label>
                                <h6>{userData.weight || 'NULL'} Kg</h6>
                            </div>
                            <h5 className='mt-3'>Cricket Experience</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Year of Experience</label>
                                <h6>{userData.experience || 'NULL'} Years</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Highest Level Played</label>
                                <h6>{userData.highest_level_played || 'NULL'}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Previous Team</label>
                                <h6>{userData.previous_Team || 'NULL'}</h6>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Any Achievement</label>
                                <h6>{userData.achievement || 'NULL'}</h6>
                            </div>
                            <h5 className='mt-5'>Availability</h5>
                            <div className='col-md-6 mt-3'>
                                <label className="form-label">Days Availabble for match per week</label>
                                <h6>{userData.availability_days || 'NULL'}</h6>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <label className="form-label">Preferred Timing for the match</label>
                                <h6>{userData.timing || 'NULL'}</h6>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='container-fluid'>
                        <div className='row mt-4'>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Name</h6>
                                    <b>{profile.name}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Gender</h6>
                                    <b>{profile.gender}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain DOB</h6>
                                    <b>{profile.dob}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <div className='row'>
                                    <h6>Captain Email</h6>
                                    <b>{profile.email}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <div className='row'>
                                    <h6>Captain Phone No.</h6>
                                    <b>{profile.number}</b>
                                </div>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <div className='row'>
                                    <h6>Captain Address</h6>
                                    <b>{profile.address + "," + profile.state + "," + profile.city}</b>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h5>Please Fill the remaining details to complete your profile. </h5>
                        <div className='row my-3'>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Playing Role</label>
                                {
                                    profile.playing_role ? <h6>{profile.playing_role}</h6> :
                                        <select className="form-select" aria-label="Default select example" name='playing_role' onChange={handlechange} value={playerProfile.playing_role || ''}>
                                            <option value="">Select your playing role</option>
                                            <option value="batsman">Batsman</option>
                                            <option value="bowler">Bowler</option>
                                            <option value="all_rounder">All-Rounder</option>
                                            <option value="wicket_keeper_batsman">Wicket-Keeper Batsman</option>
                                            <option value="wicket_keeper">Wicket-Keeper</option>
                                            <option value="wicket_keeper_bowler">Wicket-Keeper Bowler</option>
                                        </select>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Playing Position</label>
                                {
                                    profile.preferred_playing_position ? <h6>{profile.preferred_playing_position}</h6> :
                                        <select className="form-select" aria-label="Default select example" name='preferred_playing_position' onChange={handlechange} value={playerProfile.preferred_playing_position || ''}>
                                            <option value="">Select your playing position</option>
                                            <option value="Top-Order" >Top-Order</option>
                                            <option value="Middle-Order">Middle-Order</option>
                                            <option value="Lower-Order">Lower-Order</option>
                                        </select>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Batting Style</label>
                                {
                                    profile.batting_style ? <h6>{profile.batting_style}</h6> :
                                        <select className="form-select" aria-label="Default select example" name='batting_style' onChange={handlechange} value={playerProfile.batting_style || ''}>
                                            <option value="">Select your playing role</option>
                                            <option value="righty" >Right Hand</option>
                                            <option value="lefty">Left hand</option>
                                        </select>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Bowling Style</label>
                                {
                                    profile.bowling_style ? <h6>{profile.bowling_style}</h6> :
                                        <select className="form-select" aria-label="Default select example" name='bowling_style' onChange={handlechange} value={playerProfile.bowling_style || ''}>
                                            <option value="">Select your playing role</option>
                                            <option value="right_hand_fast" >Right Hand Fast</option>
                                            <option value="left_hand_fast">Left hand Fast</option>
                                            <option value="right_hand_medium_pace">Right hand Medium Pace</option>
                                            <option value="left_hand_medium_pace">Left hand Medium Pace</option>
                                            <option value="left_hand_spin">Left hand spin</option>
                                            <option value="right_hand_spin">Right hand spin</option>
                                        </select>
                                }
                            </div>
                            <h5 className='mt-3'>Physical Attributes</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Your height in cm.</label>
                                {
                                    profile.height ? <h6>{profile.height}</h6> :
                                        <input type="number" className="form-control" id="playerHeight" name='height' onChange={handlechange} value={playerProfile.height || ''}></input>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Your weight in kg.</label>
                                {
                                    profile.weight ? <h6>{profile.weight}</h6> :
                                        <input type="number" className="form-control" id="playerWeight" name='weight' onChange={handlechange} value={playerProfile.weight || ''}></input>
                                }
                            </div>
                            <h5 className='mt-3'>Cricket Experience</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Year of Experience</label>
                                {
                                    profile.experience ? <h6>{profile.experience} Years</h6> :
                                        <input type="number" className="form-control" id="yearofexperience" name='experience' onChange={handlechange} value={playerProfile.experience || ''}></input>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Highest Level Played</label>
                                {
                                    profile.highest_level_played ? <h6>{profile.highest_level_played}</h6> :
                                        <select className="form-select" aria-label="Default select example" name='highest_level_played' onChange={handlechange} value={playerProfile.highest_level_played || ''}>
                                            <option value="">Select your playing role</option>
                                            <option value="school/college" >School/College Level</option>
                                            <option value="district">District Level</option>
                                            <option value="state">State Level</option>
                                            <option value="national">National Level</option>
                                        </select>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Previous Team</label>
                                {
                                    profile.previous_Team ? <h6>{profile.previous_Team}</h6> :
                                        <select className="form-select" aria-label="Default select example" name='previous_Team' onChange={handlechange} value={playerProfile.previous_Team || ''}>
                                            <option value="team1" >Team 1</option>
                                            <option value="team2">Team 2</option>
                                            <option value="team3">Team 3</option>
                                        </select>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Any Achievement</label>
                                {
                                    profile.achievement ? <h6>{profile.achievement}</h6> :
                                        <input type="text" className="form-control" id="achievement" name='achievement' onChange={handlechange} value={playerProfile.achievement || ''}></input>
                                }
                            </div>
                            <h5 className='mt-5'>Availability</h5>
                            <div className='col-md-6 mt-3'>
                                <label className="form-label">Days Availabble for match per week</label>
                                {
                                    profile.availability_days ? <h6>{profile.availability_days}</h6> :
                                        <input type="text" className="form-control" id="availability" name='availability_days' onChange={handlechange} value={playerProfile.availability_days || ''}></input>
                                }
                            </div>
                            <div className='col-md-6 mt-3'>
                                <label className="form-label">Preferred Timing for the match</label>
                                {
                                    profile.timing ? <h6>{profile.timing}</h6> :
                                        <select className="form-select" aria-label="Default select example" name='timing' onChange={handlechange} value={playerProfile.timing || ''}>
                                            <option value="">Select your playing role</option>
                                            <option value="morning" >Morning</option>
                                            <option value="afternoon">Afternoon</option>
                                            <option value="evening">Evening</option>
                                        </select>
                                }
                            </div>
                            <h5 className='mt-3'>Social Media Links</h5>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">InstaGram</label>
                                {
                                    profile.instagram_links ? <h6>
                                        <Link to={profile.instagram_links}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                            </svg>
                                        </Link>
                                    </h6> :
                                        <input type="text" className="form-control" id="availability" placeholder='Paste your instagram link here ' name='instagram_links' onChange={handlechange} value={playerProfile.instagram_links || ''}></input>
                                }
                            </div>
                            <div className='col-md-6 mt-3 col-6'>
                                <label className="form-label">Facebook</label>
                                {
                                    profile.facebook_links ? <h6><Link to={profile.facebook_links}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                                    </svg></Link></h6> :
                                        <input type="text" className="form-control" id="availability" placeholder='Paste your Facebook profile link here ' name='facebook_links' onChange={handlechange} value={playerProfile.facebook_links || ''}></input>
                                }
                            </div>
                            <button type='button' className='btn btn-dark mt-3' style={{ width: "20%" }} onClick={submitprofile}>Save</button>
                        </div>
                    </div>
            }
        </>
    )
}

export default About
